.bl-brands-modal {
  .modal-header-container {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    width: 523px;
  }

  .modal-header-text {
    display: flex;
    justify-content: space-between;
  }

  .modal-chip-container {
    &.MuiBox-root {
      box-sizing: border-box;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
      width: 523px;
      min-height: 70px;
      padding: 12px 16px;
    }
  }

  .modal-header-chips {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0.75em 1em;
  }

  .modal-footer {
    padding-left: 1.1rem;
    padding-right: 1.1rem;
    height: 80px;
    box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1);

    .container-error {
      display: flex;
      align-items: center;

      .error-text {
        margin-left: 0.5em;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
        color: var(--bluon--default--peach);
        margin-top: 0.25em;
      }
    }

    .cancel-btn {
      margin-right: 0.5em;
    }
  }

  .modal-content {
    padding: 0;
    width: 523px;
    height: 560px;
  }

  .search-textfield {
    margin: 0 1em 1em 1em;
  }

  .not-found-search {
    background: var(--bluon--light--gray-4);
    height: 100%;

    img {
      margin-bottom: 2em;
    }

    .MuiTypography-h6 {
      margin-bottom: 1em;
    }
  }
}
