.bl-manuals-list {
  &.MuiBox-root {
    .MuiButton-text {
      background-color: var(--bluon--default--white);
    }
  }

  img {
    height: 35px;
    margin-left: 1.25em;
    margin-right: 1.875em;
  }
}
