.bl-spec-group-title {
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 0.875rem;
  letter-spacing: -0.015rem;
  font-style: normal;
  padding: 1.7em 1em 0.5em 1em;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.bl-spec-group-items {
  background-color: var(--bluon--default--white);
  padding-right: 1.875em;
  padding-left: 1.875em;
}
