.bl-confirm-modal-dialog-container {
  .MuiPaper-root {
    width: 400px;
  }

  .bl-confirm-modal-avatar-container {
    display: flex;
    justify-content: center;
    margin: 0 1em 1em 1em;
  }

  .bl-confirm-modal-dialog-text {
    color: var(--bluon--default--black);
    text-align: center;
    font-weight: bold;
  }

  .bl-confirm-modal-form-control-container {
    display: flex;
    border-bottom: 1px solid var(--bluon--light--gray-11);
    margin-bottom: 0.8em;

    .bl-confirm-modal-radio-group-container {
      display: flex;
      justify-content: center;

      .bl-confirm-modal-form-control-label {
        margin: 0;
      }
    }

    .bl-confirm-modal-helper-text {
      display: flex;
      justify-content: center;
      margin-bottom: 0.8em;
      font-size: 1rem;
      color: var(--bluon--default--peach);
    }
  }

  .bl-confirm-modal-input {
    margin-bottom: 1.5em;
  }

  .bl-confirm-modal-buttons-container {
    justify-content: center;
    column-gap: 10px;
  }

  .bl-confirm-modal-cancel-button-container {
    text-align: right;
  }
}
