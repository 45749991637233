.bl-search-breadcrumb-container {
  align-items: center;
  border-bottom: 1.5px solid var(--bluon--light--gray-3);
  display: flex;
  height: 70px;
  padding: 0 3em;

  .bl-search-breadcrumb-separator {
    margin: 0 1.5em;
  }

  .bl-search-breadcrumb-item {
    align-items: center;
    display: flex;

    &:hover {
      text-decoration: none;
    }

    > img {
      height: 25px;
    }

    .bl-search-breadcrumb-select-item-txt {
      color: var(--bluon--default--black);
      font-size: 1rem;
      font-weight: 400;
      letter-spacing: -0.288px;
      line-height: 26.4px;
      margin-left: 0.5em;

      &.no-picture {
        margin-left: 0;
      }
    }
  }

  .bl-search-breadcrumb-select-txt {
    color: var(--bluon--default--blue--gray-3);
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: -0.288px;
    line-height: 26.4px;
  }
}
