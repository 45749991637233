.bl-not-found-container {
  &.MuiContainer-root {
    align-items: center;
    background-color: var(--bluon--light--gray-8);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin: 0;
  }

  .bl-not-found-img-container {
    margin-bottom: 1em;
    img {
      height: auto;
      max-width: 200px;
    }
  }

  .not-found-title {
    color: var(--bluon--light--navy-2);
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 0.5em;
  }

  .not-found-subtitle {
    color: var(--bluon--light--navy-2);
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 28px;
    max-width: 80%;
  }
}
