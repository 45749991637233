.specs-container {
  overflow: auto;
  border-bottom: 1px dashed var(--bluon--darker--gray);
  max-height: 323px;
  .specs-item-container {
    border-bottom: 1px solid var(--bluon--light--gray-3);
    display: flex;
    justify-content: space-between;
    padding: 0 1em;

    .title {
      &.MuiTypography-caption {
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 32px;
        letter-spacing: -0.24px;
        color: var(--bluon--darker--navy);
      }
    }

    .value {
      &.MuiTypography-caption {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 32px;
        letter-spacing: -0.24px;
        color: var(--bluon--darker--navy);
      }
    }
  }
}
