.iframe-container {
  padding: 1em 2em 0em 2em;
  height: calc(100vh - (9em + 170px));
  display: flex;
  flex-direction: column;

  iframe {
    flex: 1 1 auto;
  }
}
