.row-item-search-models-container {
  height: 76.8px;
  width: 100%;

  .row-item-search-models-container-content {
    align-items: center;
    border-bottom: 1.5px solid var(--bluon--light--gray-3);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
    padding-left: 1.5em;
    padding-right: 1.913em;
    width: 100vw;

    .row-item-search-models-info-section {
      align-items: center;
      display: flex;
      width: 100%;

      .row-item-search-models-brand {
        align-items: center;
        display: flex;
        margin-right: 0.938em;

        .item-img-brand {
          height: 40px;
          margin-right: 0.938em;
          object-fit: contain;
          width: 60px;
        }
      }

      .row-item-search-models-series {
        align-items: center;
        display: flex;
        margin-right: 0.938em;

        .item-img-series {
          height: 45px;
          margin-right: 0.938em;
          object-fit: contain;
          width: 60px;
        }

        .models-series-txt {
          color: var(--bluon--default--black);
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          letter-spacing: -0.288px;
          line-height: 26px;
          margin-right: 0.938em;
        }
      }

      .chevron-icon-info {
        color: var(--bluon--lighter--navy);
        height: 26px;
        width: 20px;
      }

      .row-item-search-models-model {
        display: flex;
        flex-direction: column;

        .models-model-txt {
          color: var(--bluon--default--black);
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          letter-spacing: -0.288px;
          line-height: 26px;
          text-align: start;
        }

        .models-model-notes-txt {
          color: var(--bluon--light--gray-15);
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          letter-spacing: -0.288px;
          line-height: 22px;
          text-align: start;
        }
      }
    }

    .chevron-icon {
      color: var(--bluon--default--blue--gray-5);
      height: 30px;
      width: 30px;
    }
  }
}
