.bl-search-disclaimer {
  background-color: var(--bluon--light--gray-14);
  padding: 1em;
  margin: 1em 2em 0em 2em;

  .bl-search-disclaimer-message {
    font-size: 0.83rem;
    text-align: center;
    color: var(--bluon--light--navy-2);

    .bl-search-disclaimer-message-header {
      font-weight: bold;
    }
  }
}
