.children-container {
  &.MuiBox-root {
    border: 1px solid var(--bluon--light--gray);
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 8px;
  }
}

.special-input-selector-form-box {
  margin-bottom: 24px;
}
