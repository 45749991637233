.bl-account-container {
  .bl-account-container-tab-and-logout {
    display: flex;
    background-color: var(--bluon--default--white);
    justify-content: space-between;

    & .MuiTabs-root {
      background-color: var(--bluon--default--white);
      padding-left: 2em;

      .MuiTab-textColorPrimary {
        color: var(--bluon--default--black);
        font-weight: 400;
        text-transform: none;
      }

      .Mui-selected {
        font-weight: 700;
      }

      .MuiTabs-indicator {
        height: 4px;
      }
    }

    .bl-account-log-out-container {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 31px;
      width: 97px;
      align-self: flex-end;
      margin-right: 0.563em;
      margin-bottom: 0.531em;
      border: none;
      background-color: unset;

      .logout-text {
        margin-left: 0.5em;
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.188rem;
        text-align: center;
        text-decoration-line: underline;
        color: var(--bluon--brand--blue);
      }
    }
  }

  .bl-account-sub-container {
    .MuiBox-root {
      padding: 0;
    }
  }
}
