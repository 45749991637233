.bl-spec-item {
  background-color: var(--bluon--default--white);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;

  .bl-spec-label,
  .bl-spec-value {
    color: var(--bluon--darker--navy);
    font-size: 0.9rem;
    font-style: normal;
    letter-spacing: -0.24px;
    line-height: 1.438rem;
    min-height: 1.5rem;
    border-bottom: 1px solid var(--bluon--light--gray-11);
    white-space: nowrap;

    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .bl-spec-label {
    font-weight: 700;
  }

  .bl-spec-value {
    font-weight: 500;
    text-align: right;
  }
}
