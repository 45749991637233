.bl-login-container {
  height: 100vh;

  .container-center {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
  }

  .login-side-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    &.logo-side {
      background-color: var(--bluon--lighter--navy);
    }

    &.form-side {
      background-color: var(--bluon--default--white);
    }

    .grow-map {
      padding-bottom: 0.5rem;
    }

    .bl-logo-title-container {
      width: 180px;

      .bluon-logo {
        width: inherit;
        padding-top: 0.5rem;
        padding-bottom: 2rem;
      }
    }
  }
}
