.time-picker-min-width {
  &.MuiOutlinedInput-root {
    cursor: pointer;
    min-width: 130px;
    padding: 0 0 0 1rem;
    background-color: var(--bluon--light--gray-6);
    max-height: 2.75rem;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}
