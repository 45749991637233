.bl-model-detail-parts-tab-container {
  &.MuiContainer-root {
    height: calc(100vh - 333px);
    margin: 0;
    max-width: 100%;
    padding: 0;
    width: 100%;
  }

  .bl-model-detail-parts-container-title {
    margin-top: 1em;
  }

  .bl-model-detail-parts-container-parts {
    background-color: var(--bluon--default--white);
    height: calc(100vh - 349px);
  }

  .bl-model-detail-parts-container-loading {
    align-items: center;
    background-color: var(--bluon--default--white);
    display: flex;
    height: inherit;
    justify-content: center;
    overflow: hidden;
  }

  .bl-model-detail-container-parts-details {
    background-color: var(--bluon--default--white);
    height: inherit;
    border-left: 1px solid var(--bluon--light--gray-8);

    .bl-part-tab-container-part-detail {
      background-color: var(--bluon--default--white);
      height: calc(100vh - 350px);
      overflow-y: auto;
      width: 100%;
    }

    .bl-part-details-container {
      height: inherit;
      max-width: 100%;
    }
  }
}
