.bl-set-new-password-container {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .bl-set-new-password-title-container {
    margin-bottom: 2em;
  }

  .bl-set-new-password-button-container {
    margin-bottom: 1.875em;
  }

  .bl-take-rate-container {
    font-weight: 500;
    font-size: 0.875rem;
    color: var(--bluon--darker--gray);
    margin-bottom: 2rem;
  }

  .go-back-button {
    .MuiSvgIcon-root {
      font-size: 15px;
      padding-bottom: 5px;
    }
  }
}
