.notes-caption-container {
  overflow: auto;
  max-height: 333px;

  .notes-caption {
    &.MuiTypography-caption {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 32px;
      letter-spacing: -0.24px;
      color: var(--bluon--darker--navy);
      white-space: pre-line;
    }
  }
}

.item-info-tabs {
  &.MuiTabs-root {
    min-height: 34px;
  }
  .MuiTab-root {
    min-height: 34px;
    padding: 0;
    text-transform: capitalize;

    &.Mui-selected {
      color: var(--bluon--default--black);
      font-weight: 700;
    }
  }
  .MuiTabs-scroller {
    height: 34px;
  }
}
