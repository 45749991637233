.bl-search-part-tab-container {
  &.MuiContainer-root {
    background-color: var(--bluon--default--white);
    height: calc(100vh - 135px);
    margin: 0;
    max-width: 100%;
    padding: 0;
    width: 100%;
  }

  .bl-search-input-container {
    &.bl-search-input-part {
      &.MuiContainer-root {
        margin: 0 0;
        max-width: none;
        padding-top: 0.938em;
        padding: 1em 2em;
      }

      .bl-search-input {
        border-width: 2px;
        border-color: var(--bluon--default--blue-1);
        background-color: var(--bluon--default--white);
        font-size: 1rem;
        font-weight: 400;
        height: 48px;
        line-height: 18.75px;
        width: 100%;
  
        ::placeholder {
          font-style: normal;
        }
  
        > svg {
          margin-right: 0.5em;
        }
      }
  
      .MuiFormHelperText-root {
        display: none;
      }
    }
  }

  .bl-search-part-tab-content {
    &.MuiContainer-root {
      height: calc(100vh - 150px);
      margin: 0;
      max-width: 100%;
      padding: 0;
      width: 100%;
    }

    .bl-search-part-default-info {
      display: flex;
      justify-content: space-between;
      padding-top: 1em;
      padding: 0 2em;

      .bl-search-part-helper-title {
        color: var(--bluon--light--navy-2);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.75rem;
      }

      .bl-search-part-helper-link-faq {
        color: var(--bluon--brand--blue);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.75rem;
        text-decoration: underline;
      }
    }

    .bl-search-part-not-found {
      height: 100%;
    }

    .bl-part-tab-container-parts {
      background-color: var(--bluon--default--white);
      height: calc(100vh - 246px);
    }

    .bl-part-tab-loading-container {
      align-items: center;
      background-color: var(--bluon--default--white);
      display: flex;
      height: calc(100vh - 246px);
      justify-content: center;
      overflow: hidden;
    }

    .bl-part-tab-container-part-detail {
      background-color: var(--bluon--default--white);
      border-left: 1px solid var(--bluon--light--gray-8);
      height: calc(100vh - 290px);
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
