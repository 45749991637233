// Base Rules
@import 'assets/scss/fonts';
@import 'assets/scss/base';
@import 'assets/scss/colors';
@import 'assets/scss/colorsDS';

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
//chat
 .time-message{
    @apply flex flex-col items-center justify-items-center pt-3 pb-1 text-xs text-bluon_dark_navy opacity-50
 }

 .item-chat {
   @apply flex items-center gap-2
 }
}