$scrollbar-padding: 15px;

html,
body {
  min-height: 100vh;
  background-color: var(--bluon--light--gray-4);
  font-family: 'Roboto', sans-serif;
  margin: 0;

  //Custom scrollbar (ios like)
  ::-webkit-scrollbar {
    height: 10px;
    width: 14px;
    background: inherit;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--bluon--light--gray-13);
    -webkit-border-radius: 1vw;
    border-radius: 1vw;
  }

  ::-webkit-scrollbar-track-piece {
    background: var(--bluon--light--gray-3);
    border-left: 1px solid rgba(1, 91, 188, 0.1);
    border-right: 1px solid rgba(1, 91, 188, 0.1);
  }

  ::-webkit-scrollbar-corner {
    background: inherit;
  }

  @media only screen and (min-width: 600px) {
    font-size: 85%;
  }

  @media only screen and (min-width: 900px) {
    font-size: 90%;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 95%;
  }

  @media only screen and (min-width: 1440px) {
    font-size: 100%;
  }
}
