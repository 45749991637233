.bl-totals-container {
  .MuiTypography-caption {
    font-size: 0.875rem;
    line-height: 1.251rem;
  }

  .MuiTypography-h5 {
    font-size: 1.25rem;
  }

  .bl-totals-container-inputs-upload {
    margin-left: 0.5em;
    margin-right: 1em;

    .container-total-inputs {
      padding-left: 0.5em;
      padding-right: 1.75em;
    }

    .container-upload-section {
      padding-left: 1.75em;
    }
  }
}

.accordion-styles {
  &.MuiAccordion-root {
    background-color: var(--bluon--ds--lightgray-5);
    border: 1px solid var(--bluon--ds--lightgray-6);
  }
}

.curri-delivery-tracker {
  .MuiButtonBase-root {
    background-color: var(--bluon--default--black);
    &:hover {
      background-color: var(--bluon--default--black);
    }
  }
}
