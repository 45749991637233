.counter-staff-divider {
  width: 75%;
  border: 1px solid var(--bluon--default--blue--gray-6);
  margin-bottom: 1em;
}

.grid-container-phone-label-toggle {
  .container-phone-input-labels {
    .phone-input-label {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.75rem;
      text-align: left;
      margin-right: 0.25em;
    }

    .phone-input-sublabel {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.75rem;
      text-align: left;
      color: var(--bluon--darker--gray);
      font-style: italic;
    }
  }
}

.grid-container-phone-input {
  .MuiBox-root {
    width: 100%;
  }
}
