.bl-truck-stock {
  .check-icon {
    &.MuiSvgIcon-root {
      color: var(--bluon--default--green);
      background-color: var(--bluon--light--green-2);
      border-radius: 1rem;
      padding: 0.1rem;
    }
  }

  &.MuiAccordion-root {
    border: none;
  }

  .MuiAccordionDetails-root {
    padding: 0 1rem 1rem;
  }

  .MuiCheckbox-root.Mui-checked {
    color: var(--bluon--ds--brand);
  }
  .added-items-title {
    .MuiCheckbox-root.Mui-checked {
      color: var(--bluon--default--green);
    }
  }

  .bl-added-items-container {
    background-color: var(--bluon--default--blue--gray-9);
  }
  .MuiOutlinedInput-input {
    padding: 0.531rem 0;
    background-color: var(--bluon--default--white);
  }
  .quantity-input {
    width: 3.6rem;

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .error-message {
    &.MuiTypography-subtitle1 {
      font-style: italic;
      font-size: 0.75rem;
      line-height: 0.75rem;
      color: var(--bluon--default--red);
    }
  }

  .truck-stock-title {
    &.MuiTypography-subtitle1 {
      color: var(--bluon--ds--black);
      font-weight: 700;
      font-size: 0.875rem;
    }
  }

  .head-title {
    &.MuiTypography-subtitle1 {
      font-weight: 500;
      font-size: 0.875rem;
      letter-spacing: 0.011rem;
    }
  }
}
