.bluon-nav {
  max-height: 4rem;

  .container-logo,
  .container-routes {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .container-logo {
    gap: 1rem;  
  }

  .container-routes {
    flex: 1;
    justify-content: center; 
  }

  .dropdown-image {
    object-fit: contain;
    height: 1.5rem;
    max-width: 13.125rem;
  }
}
