.bl-header-search-nav {
  &.MuiPaper-root {
    background-color: var(--bluon--ds--lightgray);
    display: flex;
    flex-direction: row;
    margin-left: 0;
    width: 100%;

    & .MuiTabs-root {
      align-items: flex-end;
      display: flex;
      margin-left: 16px;

      .MuiButtonBase-root {
        padding: 24px 32px;
      }

      .MuiTab-textColorPrimary {
        color: var(--bluon--default--black);
        font-size: 1rem;
        font-weight: 400;
        line-height: 21.09px;
        text-transform: none;
      }

      .Mui-selected {
        font-weight: 700;
      }

      .MuiTabs-indicator {
        height: 4px;
      }
    }
  }

  .toolbar-header {
    padding: 0;
  }
}
