.bl-search-input-container {
  
  &.bl-search-input-custom-equipment {
    &.MuiContainer-root {
      margin: 0 0;
      max-width: none;
      padding-top: 0.938em;
      padding: 1em 2em;
    }

    .bl-search-input {
      border-width: 2px;
      border-color: var(--bluon--default--blue-1);
      background-color: var(--bluon--default--white);
      font-size: 1rem;
      font-weight: 400;
      height: 48px;
      line-height: 18.75px;
      width: 100%;

      ::placeholder {
        font-style: normal;
      }

      > svg {
        margin-right: 0.5em;
      }
    }

    .MuiFormHelperText-root {
      display: none;
    }
  }
}

.bl-search-content-container {
  &.MuiContainer-root {
    background-color: var(--bluon--default--white);
    margin: 0;
    padding: 0;
    max-width: none;
  }

  .bl-container-sections {
    height: calc(100vh - (5em + 204px));
    background-color: var(--bluon--default--white);

    .bl-search-loading-section-container {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      overflow: hidden;
    }
  }
}

.bl-not-found-container {
  &.MuiContainer-root {
    background-color: var(--bluon--default--white);
    height: 100%;
    margin: 0;
    padding: 0;
    max-width: none;
  }
}
