.bl-search-sections-item-btn {
  width: 100%;

  &.MuiButton-root {
    &.selected {
      background-color: var(--bluon--light--blue-2);
      box-shadow: inset -6px 0px 0px var(--bluon--brand--blue);
      border-radius: 0;
    }
  }

  .bl-search-sections-item-container {
    align-items: center;
    border-bottom: 1.5px solid var(--bluon--light--gray-3);
    display: flex;
    flex-direction: row;
    height: 65px;
    justify-content: space-between;
    padding-left: 1.5em;
    padding-right: 1.913em;
    cursor: pointer;
    width: 100vw;

    .bl-search-sections-info-container {
      align-items: center;
      display: flex;
      width: 100%;

      .bl-item-img,
      .bl-item-img-place {
        height: auto;
        margin-right: 1.5em;
        width: 100%;
        max-width: 50px;
        object-fit: contain;
      }

      .bl-item-img {
        &.search-series {
          max-width: 50px;
          width: 100%;
          height: auto;
          max-height: 60px;
        }
      }

      .bl-part-tab-item-img {
        margin-right: 1.5rem;
        height: auto;
        width: 100%;
        max-width: 40px;
        object-fit: contain;
      }

      .bl-search-sections-info-title-notes-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(100% - 114px);

        .bl-item-name,
        .bl-item-notes {
          text-align: start;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .bl-item-name {
          color: var(--bluon--default--black);
          font-size: 1rem;
          font-weight: 400;
          letter-spacing: -0.288px;
          line-height: 26.4px;

          &.small {
            font-size: 1rem;
            font-weight: 400;
            letter-spacing: -0.288px;
            line-height: 26.4px;
          }

          &.medium {
          }

          &.big {
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.375rem;
            letter-spacing: -0.017rem;
            margin-bottom: 0.3em;
          }
        }

        .bl-item-notes {
          color: var(--bluon--darker--gray);
          font-weight: 400;
          line-height: 15px;

          &.small {
            font-size: 0.875rem;
            letter-spacing: -0.018rem;
          }

          &.medium {
          }

          &.big {
            font-size: 0.85rem;
          }

          &.black {
            color: var(--bluon--default--black);
          }

          &.gray {
            color: var(--bluon--darker--gray);
          }
        }
      }
    }

    .chevron-icon {
      color: var(--bluon--default--blue--gray-5);
      height: 30px;
      width: 30px;
      // margin-right: 1.913rem;
    }
  }
}
