.banner-wrapper {
  .banner-message {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  .banner-button-wrapper {
    .MuiButtonBase-root {
      background-color: var(--bluon--default--white);
      text-transform: uppercase;
      padding: 0.25rem 0.625rem;
      font-size: 0.813rem;
      line-height: 1.375rem;

      &.primary-button {
        color: var(--bluon--ds--brand);
        border: 1px solid var(--bluon--ds--button--primary--border);
      }

      &.error-button {
        color: var(--bluon--light--red-4);
        border: 1px solid var(--bluon--light--red-4);
      }
    }
  }
}
