.bl-part-tab-part--detail-header-container {
  margin-bottom: 1em;
  padding: 0 2em;

  .bl-part-tab-part-detail-header {
    display: flex;
    padding-top: 1.625em;

    .bl-part-tab-part-detail-img {
      align-items: center;
      display: flex;
      max-width: 80px;
      height: auto;
      justify-content: center;
      margin-bottom: 0.9em;
      margin-right: 1.5em;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .bl-part-tab-part-detail-info-header {
      .bl-part-tab-part-detail-info-header-name,
      .bl-part-tab-part-detail-info-header-model {
        color: var(--bluon--darker--navy);
        font-size: 1.3rem;
      }

      .bl-part-tab-part-detail-info-header-name {
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.27px;
        line-height: 1.375rem;
        margin-bottom: 0.2em;
      }

      .bl-part-tab-part-detail-info-header-model {
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.24px;
        margin-bottom: 1em;
      }

      .bl-part-tab-part-detail-info-header-replacements {
        align-items: center;
        display: flex;

        .bl-part-tab-part-detail-info-header-replacements-txt {
          color: var(--bluon--default--green);
          font-size: 0.9rem;
          font-style: normal;
          font-weight: 500;
          letter-spacing: -0.24px;
          line-height: 1.375rem;
          margin-right: 0.56em;
        }

        .bl-part-tab-part-detail-info-header-first-icon {
          align-items: center;
          color: var(--bluon--default--green);
          display: flex;
          margin-right: 0.56em;
        }

        .bl-part-tab-part-detail-info-header-last-icon {
          align-items: center;
          color: var(--bluon--light--navy-3);
          display: flex;

          svg {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
  }

  .bl-part-tab-part-detail-specifications {
    .bl-part-tab-part-detail-specifications-txt {
      color: var(--bluon--darker--gray);
      font-size: 1.375rem;
      font-style: normal;
      font-weight: 500;
      letter-spacing: -0.27px;
      line-height: 1.375rem;
    }

    .bl-part-tab-part-detail-specifications-content {
      .bl-part-tab-part-detail-specifications-items {
        align-items: center;
        border-bottom: 1px solid var(--bluon--light--gray-8);
        display: flex;
        justify-content: space-between;

        .bl-part-tab-part-specifications-items-title-txt {
          color: var(--bluon--darker--navy);
          font-size: 0.9rem;
          font-style: normal;
          font-weight: 700;
          letter-spacing: -0.24px;
          line-height: 1.438rem;
          text-transform: capitalize;
        }

        .bl-part-tab-part-specifications-items-value-txt {
          color: var(--bluon--darker--navy);
          font-size: 0.9rem;
          font-style: normal;
          font-weight: 500;
          letter-spacing: -0.24px;
          line-height: 1.438rem;
        }
      }

      .bl-part-tab-part-detail-specifications-show-hide {
        align-items: center;
        border-top: 1px dashed var(--bluon--darker--gray);
        display: flex;
        justify-content: center;
        padding-top: 0.5em;

        .bl-part-tab-part-detail-specifications-show-hide-txt {
          color: var(--bluon--brand--blue);
          cursor: pointer;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          letter-spacing: -0.24px;
          line-height: 1.026rem;
          text-decoration: underline;
        }
      }
    }
  }
}

.bl-part-tab-part-detail-replacements {
  .bl-part-tab-part-detail-container-title-and-suggestion {
    display: flex;
    align-items: center;

    .bl-part-tab-part-detail-replacements-txt {
      padding-left: 2em;
      padding-right: 1em;
      color: var(--bluon--darker--gray);
      font-size: 1.375rem;
      font-style: normal;
      font-weight: 500;
      letter-spacing: -0.27px;
      line-height: 1.375rem;
      width: auto;
    }
  }

  .bl-part-tab-part-detail-replacements-container {
    display: flex;
    margin-top: 1em;
  }

  .bl-part-tab-part-detail-replacements-notes-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: unset;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: calc(100vh - 345px);

    .bl-part-tab-part-detail-replacements-notes-container-tabs {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      flex-direction: column-reverse;
      position: relative;
      border-top: 1px solid var(--bluon--light--gray-3);

      .bl-part-tab-part-detail-replacements-notes-icon {
        position: relative;
        top: -74px;
        left: 93%;
        width: 25px;
        border-top: 1px solid var(--bluon--light--gray-3);
        border-left: 1px solid var(--bluon--light--gray-3);
        border-right: 1px solid var(--bluon--light--gray-3);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .close-notes-icon {
        color: var(--bluon--default--black);
        cursor: pointer;
      }

      & .MuiTabs-root {
        align-items: flex-end;
        height: 40px;
        width: 100%;

        .MuiTabs-flexContainer {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
        }

        .MuiTab-root {
          width: 50%;
          border-bottom: 1px solid var(--bluon--light--gray-3);
        }

        .MuiTab-textColorPrimary {
          color: var(--bluon--default--black);
          font-size: 1rem;
          font-weight: 400;
          line-height: 21.09px;
          text-transform: none;
        }

        .Mui-selected {
          font-weight: 700;
        }

        .MuiTabs-indicator {
          height: 4px;
        }

        .Mui-disabled {
          color: var(--bluon--light--gray);
        }
      }
    }
  }

  .open-panel {
    border-right: 1px solid var(--bluon--light--gray-3);
  }

  .bl-tab-content-container {
    padding-left: 1em;
    padding-right: 1em;
    border: none;
    overflow-x: unset;
    overflow-y: auto;
    padding-bottom: 2.281rem;

    &.show-suggestion-btn {
      padding-bottom: 3em;
    }

    > div > div {
      padding: 0;
    }

    .loading-container-center {
      min-height: 150px;
      margin-left: 5%;
    }

    .bl-something-wrong-container {
      background-color: var(--bluon--default--white);
      margin-left: 5%;
      width: 95%;
    }

    .bl-no-specs-availables {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-left: 5%;

      svg {
        margin-top: 0.625em;
        margin-bottom: 0.313em;
        color: var(--bluon--brand--blue);
      }
    }

    .bl-replacements-notes {
      font-size: 0.9rem;
      text-align: justify;
    }
  }

  .bl-part-tab-part-detail-replacements-item-container {
    height: auto;
    min-width: 50%;
    width: 100%;

    &.show-suggestion-btn {
      padding-bottom: 3em;
    }

    &.open-panel {
      overflow-y: auto;

      &.search-by-part {
        height: calc(100vh - (312px + 200px + 70px));
      }
    }

    .replacements-loading-container,
    .not-parts-found {
      display: flex;
      width: 100%;
      height: 20px;
      overflow: hidden;
      background-color: var(--bluon--default--white);
    }

    .bl-part-tab-part-detail-specifications-show-hide {
      align-items: center;
      border-top: 1px dashed var(--bluon--darker--gray);
      display: flex;
      justify-content: center;
      padding-top: 0.5em;

      .bl-part-tab-part-detail-specifications-show-hide-txt {
        color: var(--bluon--brand--blue);
        cursor: pointer;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.24px;
        line-height: 1.026rem;
        text-decoration: underline;
      }
    }

    .bl-part-tab-part-detail-replacements-items {
      align-items: center;
      display: flex;
      justify-content: space-between;
      height: 48px;
      padding: 5px;
      padding-left: 2em;

      svg {
        font-size: 1rem;
        font-weight: 500;
        color: var(--bluon--default--blue--gray-5);
        margin-right: 0.94em;
      }

      .bl-part-tab-part-detail-replacement-item-info {
        display: flex;
        align-items: center;
      }

      .bl-part-tab-part-detail-replacement-item-img {
        align-items: center;
        display: flex;
        height: 40px;
        justify-content: center;
        margin-right: 1.625em;
        max-width: 40px;
        object-fit: contain;

        img {
          height: 100%;
          width: 100%;
        }

        .bl-part-tab-part-detail-specifications-show-hide {
          align-items: center;
          border-top: 1px dashed var(--bluon--darker--gray);
          display: flex;
          justify-content: center;
          padding-top: 0.5em;

          .bl-part-tab-part-detail-specifications-show-hide-txt {
            color: var(--bluon--brand--blue);
            cursor: pointer;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            letter-spacing: -0.24px;
            line-height: 1.026rem;
            text-decoration: underline;
          }
        }
      }

      .bl-part-tab-part-detail-replacement-item-info {
        .bl-part-tab-part-detail-replacement-item-text {
          color: var(--bluon--darker--navy);
          font-size: 1rem;
          font-weight: 400;
          letter-spacing: -0.015rem;
          line-height: 1.313rem;
        }
      }
    }

    .clickeable:hover {
      background-color: var(--bluon--light--blue-4);
      cursor: pointer;
    }

    .selected,
    .selected:hover {
      background-color: var(--bluon--light--blue-2);
      cursor: default;
    }

    .bl-part-detail-loading-more-container {
      height: 48px;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      display: flex;
    }
  }
}

.bl-part-tab-container-part-detail {
  display: flex;
  flex-direction: column;

  .bl-part-tab-part-detail-container-suggestion-btn {
    align-items: center;
    background-color: var(--bluon--default--white);
    bottom: 0;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
    display: flex;
    height: 48px;
    justify-content: center;
    position: fixed;
    z-index: 2;
    width: calc(100% - 45vw);

    .suggestion-btn {
      align-items: center;
      background-color: transparent;
      border-radius: 3px;
      border: 1px solid var(--bluon--lighter--navy);
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      height: 32px;
      padding: 0.5em;

      .suggestion-btn-txt {
        color: var(--bluon--lighter--navy);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1rem;
        text-align: center;
      }

      .suggestion-btn-icon {
        margin-right: 0.5em;
      }
    }
  }
}

.bl-part-detail-loading-container {
  align-items: center;
  background-color: var(--bluon--default--white);
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
}
