.forgot-password-form-container {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .forgot-password-form-title {
    margin-bottom: 2em;
  }

  .forgot-password-button-container {
    margin-top: 2em;
    margin-bottom: 1.875em;
  }
}
