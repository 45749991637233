.bl-model-detail-container {
  background-color: var(--bluon--light--gray-8);
  width: calc(100vw);
  height: calc(100vh - 204px);

  .loading-container {
    display: flex;
    width: 100%;
    height: calc(100vh - 175px);
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .model-info-cointener {
    background-color: var(--bluon--default--white);
    width: 100%;
    display: flex;
    height: 124px;

    .custom-badge {
      .MuiBadge-badge {
        height: inherit;
        padding: 0.3125em;
        border-radius: 15px;
        color: var(--bluon--brand--blue);
        background-color: var(--bluon--light--gray-11);
        cursor: pointer;
        top: 50%;
      }
    }

    .serie-image {
      max-height: 4.6875em;
      padding: 0 1em;
      display: flex;
      align-items: center;
      flex-direction: column;

      .MuiBox-root {
        max-height: 4.6875em;
      }

      img {
        width: 100%;
        object-fit: contain;
      }

      .MuiTypography-root {
        color: var(--bluon--darker--gray);
        font-size: 0.625rem;
      }
    }

    .serie-data {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      .MuiTypography-body1 {
        color: var(--bluon--default--black);
        font-style: normal;
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 26px;
        margin-top: 0.4em;
      }
      .MuiTypography-body2 {
        color: var(--bluon--darker--navy);
        font-style: normal;
        font-weight: 400;
        font-size: 0.938rem;
        line-height: 26px;
      }

      img {
        height: auto;
        width: 100%;
        max-width: 60px;
      }
    }
  }

  .bl-tabs-container {
    background-color: var(--bluon--default--white);
    margin-top: -2.815em;

    .bl-tabs {
      &.MuiTabs-root {
        height: 50px;
        background-color: var(--bluon--default--white);

        .MuiTab-root {
          color: var(--bluon--darker--navy);
          display: flex;
          flex-direction: row-reverse;
          padding-top: 0;
        }

        .Mui-selected {
          background-color: var(--bluon--light--gray-8);

          .bl-tab-icon {
            background-color: var(--bluon--brand--blue);
          }
        }

        .Mui-disabled {
          color: var(--bluon--light--gray-2);

          .bl-tab-icon {
            background-color: var(--bluon--light--gray-2);
          }
        }

        .MuiTabs-indicator {
          top: 0;
          height: 4px;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
        }

        .bl-tab-icon {
          background-color: var(--bluon--default--blue--gray-3);
          color: var(--bluon--default--white);
          border-radius: 100%;
          height: 25px;
          width: 25px;

          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 0.395em;
          margin-bottom: 0;

          font-size: 0.75rem;
          line-height: 1.65rem;
          letter-spacing: -0.018rem;
          font-weight: 700;
        }

        .MuiButtonBase-root {
          height: 100%;
          min-height: 50px;
          padding-top: 0.938em;
          display: flex;
          align-items: center;
        }
      }
    }

    .bl-tab-parts {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  .bl-tab-content-container {
    height: calc(100vh - 333px);
    > div > div {
      padding: 0;
    }
  }
}
