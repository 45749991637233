.bl-hours-dialog {
  .MuiDialogContent-root {
    align-self: center;
  }
  .check-container {
    flex: 1;
  }
  .row-container {
    display: flex;
  }

  .dialog-title {
    margin-bottom: 2rem;
  }

  .padding-text {
    padding: 10px 10px 0 10px;
    font-size: 1rem;
  }

  .modal-footer {
    box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1);
  }

  .time-picker-styles {
    margin-bottom: 1rem;
    .MuiOutlinedInput-input {
      padding: 0 0 0 16px;
      height: 2.75em;
    }
  }
}
