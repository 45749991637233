.bl-search-page {
  overflow: hidden;
}

.bl-search-container-loading {
  align-items: center;
  background-color: var(--bluon--light--gray-2);
  display: flex;
  height: 100vh;
  justify-content: center;
  position: absolute;
  top: 0;
  width: calc(100% - 131.5px);
}

.search-container-full-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1201;
  display: none;
}
