.commercial-model-part-container {
  .part-number-container {
    .part-number {
      width: calc(100% - 20.625rem);
    }

    .Mui-disabled {
      background-color: var(--bluon--ds--lightgray-button-bg);
      opacity: 0.7;
    }
  }
}