.bl-list-item {
  &.MuiListItem-root {
    border-bottom: 1px solid var(--bluon--light--gray-7);
  }
  .bl-branch-logo {
    max-width: 60px;
    margin-right: 1rem;
    object-fit: 'contain';
  }
}
