.bl-store-info-container {
  .MuiPaper-root {
    padding: 1em;
    margin: 1.5em 0;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
  }
  .bl-confirmation-container {
    direction: row;
    align-items: center;
    justify-content: center;
  }

  .bl-take-rate-container {
    flex: 1 1 0;
  }
}
