:root {
  --bluon--ds--selected: #f8f8f8;
  --bluon--ds--brand: #0567d1;
  --bluon--ds--brand--hover: #0567d11a;
  --bluon--ds--brand--light--background: #1976d214;
  --bluon--ds--red: #de360c;
  --bluon--ds--red--background--light: #d32f2f0a;
  --bluon--ds--white: #fff;
  --bluon--ds--disabled: #a6a6a6;
  --bluon--ds--black: #000000;
  --bluon--ds--highlight: #e6f0fa;
  --bluon--ds--lightgray: #fafafa;
  --bluon--ds--lightgray-1: #626262;
  --bluon--ds--lightgray-2: #8c8c8c;
  --bluon--ds--lightgray-3: #e0e0e0;
  --bluon--ds--lightgray-4: #f0f0f0;
  --bluon--ds--lightgray-5: #f7f7f7;
  --bluon--ds--lightgray-6: #dfdfdf;
  --bluon--ds--darkblue: #294295;
  --bluon--ds--lightblack: #00000099;
  --bluon--ds--lightblack-primary: #000000de;
  --bluon--ds--lightgray-primary: #00000061;
  --bluon--ds--lightgray-background: #0000001f;
  --bluon--ds--lightgray-button-bg: #e0e0df;
  --bluon--ds--lightgray-button-text: #00000042;
  --bluon--ds--border--lightgray: #e2e2e2;
  --bluon--ds--darkblue-01: #0567d1;
  --bluon--ds--button--primary--border: #1976d280;
  --bluon--ds--lightblue: #1976d2;
  --bluon--ds--gray--bg: #f6f6f6;
  --bluon--ds--gray--3: #bfbfbf;
  --bluon--ds--lightgreen: #4caf50;
  --bluon--ds--lightred: #d32f2f;
  --bluon--ds--lighorange: #ed6c02;
  --bluon--ds--button--lightgray--border: rgba(0, 0, 0, 0.38);
  --bluon--ds--light-black: rgba(0, 0, 0, 0.6);
  --bluon--ds--button--primary--border: #1976d280;
  --bluon--ds--button--highlight--text: #1976d2;
  --bluon--ds--star--yellow: #faaf00;
  --bluon--ds--select--border: rgba(0, 0, 0, 0.23);
  --bluon--ds--curri--message: #007D31;
  --bluon--ds--search-light-black: #0000008A;
}
