.manual-and-part-indicator {
  align-items: center;
  display: flex;
  height: 22px;
  justify-content: space-between;
  width: 57px;

  img {
    color: var(--bluon--default--blue--gray-5);
    height: 100%;
    width: 100%;
  }
}
