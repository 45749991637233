.bl-container-suggestion-modal-dialog {
  .modal-stack-container-suggestion {
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    width: 430px;
    padding: 1em;

    .suggestion-modal-container-content {
      height: 500px;
      background-color: var(--bluon--default--white);
      padding: 1em 0.75em;

      .container-title {
        width: 100%;
        margin-bottom: 1em;

        .modal-title {
          font-style: normal;
          font-weight: 600;
          font-size: 1.063rem;
          line-height: 1.313rem;
          text-align: center;
          color: var(--bluon--default--black);
        }
      }

      .container-required-input {
        width: 100%;
        margin-bottom: 0.5em;

        .notes-input {
          height: auto;
          height: 88px;
          overflow-y: auto;
          display: flex;
          align-items: flex-start;
          padding-top: 1.188em;
        }

        .lb-subtitle {
          font-style: normal;
        }
      }

      .container-btns {
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        .send-and-suggest-btn {
          min-width: 191px;
        }

        .cancel-btn,
        .send-and-suggest-btn,
        .send-btn {
          height: 100%;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1rem;
        }

        .cancel-btn,
        .send-and-suggest-btn {
          margin-right: 0.5em;
        }
      }
    }
  }
}
