.bl-section-title-container-title {
  padding: 0 1em 0.5em 1em;

  .bl-section-title-txt {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 0.875rem;
    letter-spacing: -0.015rem;
    text-transform: uppercase;
  }
}
