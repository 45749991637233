.bl-search-model-tab-container {
  &.MuiContainer-root {
    background-color: var(--bluon--default--white);
    height: calc(100vh - 134px);
    margin: 0;
    max-width: 100%;
    padding: 0;
    width: 100%;
  }

  .back-to-search-container {
    align-items: center;
    border-bottom: 1.5px solid var(--bluon--light--gray-3);
    display: flex;
    height: 70px;
    padding: 0 1.5em;
    background-color: var(--bluon--default--white);

    .back-to-search-btn {
      height: 100%;

      &:hover {
        background-color: var(--bluon--default--white);
      }

      svg {
        color: var(--bluon--default--black);
      }

      .back-search-text {
        margin-left: 1em;
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 26px;
        display: flex;
        align-items: center;
        letter-spacing: -0.288px;

        strong {
          margin-left: 4px;
          font-weight: 500;
        }
      }
    }
  }

  .bl-search-input-container {
    &.bl-search-input-model {
      &.MuiContainer-root {
        margin: 0 0;
        max-width: none;
        padding-top: 0.938em;
        padding: 1em 2em;
      }

      .bl-search-input {
        border-width: 2px;
        border-color: var(--bluon--default--blue-1);
        background-color: var(--bluon--default--white);
        font-size: 1rem;
        font-weight: 400;
        height: 48px;
        line-height: 18.75px;
        width: 100%;
  
        ::placeholder {
          font-style: normal;
        }
  
        > svg {
          margin-right: 0.5em;
        }
      }
  
      .MuiFormHelperText-root {
        display: none;
      }
    }
  }

  .bl-search-model-tab-content {
    &.MuiContainer-root {
      height: calc(100vh - 134px);
      margin: 0;
      max-width: 100%;
      padding: 0;
      width: 100%;
    }

    .bl-search-model-default-info {
      display: flex;
      justify-content: space-between;
      padding-top: 1em;
      padding: 0 2em;

      .bl-search-model-helper-title {
        color: var(--bluon--light--navy-2);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.75rem;
      }

      .bl-search-model-helper-link-faq,
      .bl-search-model-links-divider {
        margin-right: 0.5em;
      }

      .bl-search-model-helper-link-faq,
      .bl-search-model-give-feedback,
      .bl-search-model-links-divider {
        color: var(--bluon--brand--blue);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.75rem;
        text-decoration: underline;
      }
    }

    .bl-search-model-not-found {
      height: 100%;
    }

    .bl-model-tab-container-models {
      background-color: var(--bluon--default--white);
      height: calc(100vh - 254px);
    }

    .bl-model-tab-loading-container {
      align-items: center;
      background-color: var(--bluon--default--white);
      display: flex;
      height: calc(100vh - 170px);
      justify-content: center;
      overflow: hidden;
    }

    .bl-search-model-results-title-container {
      padding-left: 2em;
      margin-bottom: 1em;

      .bl-search-model-results-title {
        text-transform: uppercase;
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.875rem;
        letter-spacing: -0.24px;
        color: var(--bluon--lighter--navy);
      }
    }
  }
}
