ul.clip-list-container {
  display: 'flex';
  flex-wrap: 'wrap';
  margin: 0;
  padding: 0;
}

li.clip-list-item {
  margin: 0.25em;
  list-style: none;
  display: inline-block;
}
